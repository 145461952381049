import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ForgotPresentational from './ForgotPresentational';
import { forgot } from '../../lib/fetch/auth';
import { AlertType } from '../../providers/AlertProvider';

const Forgot = () => {
  const { t } = useTranslation('FORGOT');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<AlertType | undefined>(undefined);
  const [resultTitle, setResultTitle] = useState('');
  const [resultDescription, setResultDescription] = useState('');

  const onForgot = async ({ email }: { email: string }) => {
    setLoading(true);
    const { error } = await forgot(email);
    setLoading(false);
    if (error) {
      setResult(AlertType.Error);
      setResultTitle(t('ERROR_TITLE'));
      setResultDescription(t('ERROR_DESCRIPTION'));
    } else {
      setResult(AlertType.Success);
      setResultTitle(t('SUCCESS_TITLE'));
      setResultDescription(t('SUCCESS_DESCRIPTION'));
    }
  };

  return (
    <ForgotPresentational
      loading={loading}
      result={result}
      resultTitle={resultTitle}
      resultDescription={resultDescription}
      onForgot={onForgot}
    />
  );
};

export default Forgot;
