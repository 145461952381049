import axios from 'axios';

const { REACT_APP_FORGOT_URL } = process.env;

const forgot = async (email: string) => {
  const response = await axios.post(REACT_APP_FORGOT_URL!, { email });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default forgot;
