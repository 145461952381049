import axios from 'axios';

const { REACT_APP_RESET_URL } = process.env;

interface IResetRequest {
  code: string;
  password: string;
  passwordConfirmation: string;
}

const reset = async (resetData: IResetRequest) => {
  const response = await axios.post(REACT_APP_RESET_URL!, resetData);

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default reset;
