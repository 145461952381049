import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertType } from '../../providers/AlertProvider';
import ResetPresentational from './ResetPresentational';
import { reset } from '../../lib/fetch/auth';

export interface IResetForm {
  code: string;
  password: string;
  passwordConfirmation: string;
}

const Reset = () => {
  const { t } = useTranslation('RESET');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<AlertType | undefined>(undefined);
  const [resultTitle, setResultTitle] = useState('');
  const [resultDescription, setResultDescription] = useState('');

  const onReset = async (formData: IResetForm) => {
    setLoading(true);
    const { error } = await reset(formData);
    setLoading(false);
    if (error) {
      setResult(AlertType.Error);
      setResultTitle(t('ERROR_TITLE'));
      setResultDescription(t('ERROR_DESCRIPTION'));
    } else {
      setResult(AlertType.Success);
      setResultTitle(t('SUCCESS_TITLE'));
      setResultDescription(t('SUCCESS_DESCRIPTION'));
    }
  };

  return (
    <ResetPresentational
      loading={loading}
      result={result}
      resultTitle={resultTitle}
      resultDescription={resultDescription}
      onReset={onReset}
    />
  );
};

export default Reset;
