import axios, { AxiosResponse } from 'axios';
import { IStrapiFile } from '../../interfaces';

const { REACT_APP_FIND_FILES_URL } = process.env;

const remove = async (token: string, file: IStrapiFile) => {
  const response = await axios.delete(
    `${REACT_APP_FIND_FILES_URL}/${file.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default remove;
